import fontStacks from './fontStacks';

const topNewsStyle = Object.freeze({
  fontFamily: fontStacks.base,
  href: null,
  contentNameSuffix: ':',
  sectionClasses: ({ theme, }) => ({
    border: `solid 2px ${theme.color('bg')}`,
    paddingBottom: 'calc(1rem - 2px)',
    paddingTop: 'calc(1rem - 2px)',
  }),
});

export default topNewsStyle;

const mastheadStyle = Object.freeze({
  fontStack: 'base',
  fontWeight: 700,
  hamburgerMarginEnd: '3rem',
  searchButtonMarginEnd: '1rem',
  articlePageLogoSize: 3.5,
  typeScale: -1,
  typeScaleLoggedIn: -1,
  separatorWidth: '1px',
  hamburgerSize: 2.5,
  focusBorderStyle: undefined,
});

export default mastheadStyle;

const baseColors = {
  transparent: 'rgba(0,0,0,0)',
  black: 'var(--color-black)',
  white: 'var(--color-white)',
  current: 'currentColor',
  neutral: {
    '-10': 'var(--color-neutral--10)',
    '-7': 'var(--color-neutral--7)',
    '-6': 'var(--color-neutral--6)',
    '-5': 'var(--color-neutral--5)',
    '-4': 'var(--color-neutral--4)',
    '-3': 'var(--color-neutral--3)',
    '-2': 'var(--color-neutral--2)',
    '-1': 'var(--color-neutral--1)',
    base: 'var(--color-neutral-base)',
    '+1': 'var(--color-neutral-1)',
    '+2': 'var(--color-neutral-2)',
  },
  primary: {
    '-6': 'var(--color-primary--6)',
    '-5': 'var(--color-primary--5)',
    '-4': 'var(--color-primary--4)',
    '-3': 'var(--color-primary--3)',
    '-2': 'var(--color-primary--2)',
    '-1': 'var(--color-primary--1)',
    base: 'var(--color-primary-base)',
    '+1': 'var(--color-primary-1)',
  },
  secondary: {
    '-4': 'var(--color-secondary--4)',
    base: 'var(--color-secondary-base)',
    '+1': 'var(--color-secondary-1)',
    '+2': 'var(--color-secondary-2)',
  },
  tertiary: {
    '-4': 'var(--color-tertiary--4)',
    '-3': 'var(--color-tertiary--3)',
    '-2': 'var(--color-tertiary--2)',
    '-1': 'var(--color-tertiary--1)',
    base: 'var(--color-tertiary-base)',
    '+1': 'var(--color-tertiary-1)',
    '+2': 'var(--color-tertiaryy-2)',
    '+3': 'var(--color-tertiary-3)',
  },
  quaternary: {
    '-3': 'var(--color-quaternary--3)',
    '-2': 'var(--color-quaternary--2)',
    '-1': 'var(--color-quaternary--1)',
    base: 'var(--color-quaternary-base)',
    '+1': 'var(--color-quaternary-1)',
    '+2': 'var(--color-quaternary-2)',
    '+3': 'var(--color-quaternary-3)',
  },
};

export default baseColors;

import { parseStyleProps, } from '@haaretz/htz-css-tools';

const newsletterStyle = Object.freeze({
  mobileViewVariation: 'default',
  highlight: Object.freeze({
    buttonVariant: 'neutral',
    inputVariant: 'newsletter',
  }),
  primary: Object.freeze({
    buttonVariant: 'primary',
    inputVariant: 'primaryInverse',
  }),
  single: Object.freeze({
    buttonVariant: 'neutralOpaque',
    inputVariant: 'neutral',
    frequencyStyle: ({ theme, }) => ({
      fontWeight: '700',
      color: theme.color('secondary'),
      extend: [
        theme.type(-2),
      ],
    }),
    subscriberFrequencyStyle: ({ theme, miscStyles, }) => ({
      fontWeight: '700',
      color: theme.color('neutral'),
      backgroundColor: '#ffe70c',
      textAlign: 'center',
      marginBottom: '1rem',
      paddingBottom: '0.3rem',
      paddingTop: '0.6rem',
      paddingInlineStart: '0.5rem',
      position: 'relative',
      width: '32rem',
      ':after': {
        position: 'absolute',
        top: 0,
        end: '-1.8rem',
        content: '""',
        width: 0,
        height: 0,
        borderTop: '2.45rem solid transparent',
        borderBottom: '2.45rem solid transparent',
        borderRight: '2rem solid #ffe70c',
      },
      extend: [
        ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
      ],
    }),
    recommendedTitle: ({ theme, }) => ({
      extend: [
        theme.type(1, { untilBp: 's', }), theme.type(1, { fromBp: 's', }),
      ],
    }),
    subtitleTypeScale: [
      { from: 's', until: 'l', value: 0, },
      { until: 'xl', value: 0, },
      { from: 'xl', value: -1, },
    ],
  }),
  service: Object.freeze({
    logoColor: [ 'primary', ],
    btnBackColor: [ 'neutral', ],
  }),
});

export default newsletterStyle;

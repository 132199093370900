const osakaStyle = Object.freeze({
  top: isLabelArticle => [
    { until: 'l', value: '9rem', },
    { from: 'l', value: '10rem', },
  ],
  hideNextItemLink: false,
  fontStack: 'base',
  outbrainLogoRight: 0,
  outbrainFooterWidth: '100%',
  outbrainFooterPadding: '2rem',
  articleFooterMarginStart: '1rem',
  articlePadding: '1rem',
  itemPadding: 0,
  itemPaddingLtoXl: 0,
  itemPaddingLtoXlInner: 0,
  itemPaddingUntilL: 0,
  articleImgSpacing: '1rem',
  typeUntilXl: -2,
  typeFromXl: -2,
});

export default osakaStyle;

const breakingNewsNotificationsStyle = Object.freeze({
  kicker: {
    text: 'עדכון חדשות',
  },
  arrow: {
    rotate: 0,
  },
});

export default breakingNewsNotificationsStyle;

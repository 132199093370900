const liveBlogArticleStyle = Object.freeze({
  updatesButtonTop: Object.freeze({
    default: '0',
    withOsaka: [
      { until: 'l', value: '25rem', },
      { from: 'l', until: 'xl', value: '26.5rem', },
      { from: 'xl', value: '24rem', },
    ],
    withStickyMasthead: [
      { until: 'l', value: '9rem', },
      { from: 'l', value: '10rem', },
    ],
  }),
});

export default liveBlogArticleStyle;

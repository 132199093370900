import fontStacks from './fontStacks';

const appPromotionToolStyle = Object.freeze({
  actionButtonVariant: 'neutralOpaque',
  declineButtonVariant: 'neutral',
  fontFamily: fontStacks.base,
});


export default appPromotionToolStyle;

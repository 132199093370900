const marketingBannerStyle = Object.freeze({
  fontStack: 'base',
  textTransform: 'inherit',
  topStrip: Object.freeze({
    typeScale: -1,
    buttonTypeScale: -1,
  }),
  bottomRuller: Object.freeze({
    defaultView: 'blackDiver',
  }),
});

export default marketingBannerStyle;

import baseColors from './baseColors';

const colors = {
  // Base named colors
  ...baseColors,

  // semantic use-cases
  commercial: {
    base: 'var(--commercial-base)',
    bg: [ 'neutral', '-7', ],
  },
  contactUs: {
    uploadedFileBox: [ 'secondary', 'base', ],
    needMoreHelpSelectTextColor: 'var(--contactUs-needMoreHelpSelectText-color)',
    fileUploaderBackgroundColor: 'var(--contactUs-fileUploader-bg)',
    fileUploaderBorderColor: 'var(--contactUs-fileUploader-border)',
    fileUploaderColor: 'var(--contactUs-fileUploader-color)',
    subjectButtonListColor: 'var(--contactUs-content-color)',
    subjectButtonColor: 'var(--contactUs-subjectButton-color)',
    subjectButtonActiveBorderColor: 'var(--contactUs-subjectButton-activeBorder)',
    subjectButtonBorderColor: 'var(--contactUs-subjectButton-border)',
    subjectButtonFillActiveColor: 'var(--contactUs-subjectButton-fillActiveColor)',
    subjectButtonFillColor: [ 'neutral', '-6', ],
    subjectButtonBg: 'var(--contactUs-subjectButton-bg)',
    questionColor: 'var(--contactUs-question-color)',
    questionActiveColor: 'var(--contactUs-question-activeColor)',
    questionBackgroundColor: 'var(--contactUs-question-bg)',
    questionsTitleColor: 'var(--contactUs-questionTitle-color)',
    answerHrefColor: [ 'primary', 'base', ],
    thankYouFormColor: [ 'primary', 'base', ],
    footerColor: 'var(--contactUs-footer-color)',
  },
  accent: {
    base: [ 'tertiary', 'base', ],
  },
  account: {
    title: [ 'neutral', '-2', ],
  },
  alerts: {
    bg: [ 'neutral', '-6', ],
    openButtonText: [ 'tertiary', 'base', ],
    openButtonTextFollow: [ 'neutral', '-4', ],
    openButtonTextUnfollow: [ 'tertiary', 'base', ],
  },
  articleHeader: {
    titleText: 'var(--main-text-color)',
    kickerBlockBg: [ 'tertiary', ],
    kickerBlockText: [ 'neutral', '-10', ],
    kickerInlineBg: 'transparent',
    kickerInlineText: [ 'tertiary', ],
    metaTime: 'inherit',
    metaBorder: 'var(--articleHeader-metaBorder)',
    liveBlogAuthors: 'var(--articleHeader-liveBlogAuthors)',
    headlineCaptionText: 'inherit',
    headlineCaptionTextMobile: 'white',
    headlineCaptionBgMobile: 'var(--articleHeader-headlineCaptionBgMobile)',
    highlightMobileCaptionBgc: [ 'neutral', '-1', ],
    reportingFrom: [ 'positive', ],
    aVariationDates: 'var(--articleHeader-aVariationDates)',
    logoColor: [ 'primary', 'base', ],
  },
  articleLink: {
    currentArticleBg: 'var(--articleLink-currentArticleBg)',
    currentArticleText: 'var(--articleLink-currentArticleText)',
    color: 'var(--articleLink-color)',
    colorBlockLink: 'inherit',
    borderBlockLink: 'inherit',
    hoverColor: 'white',
    bgImage: 'var(--articleLink-bgImage)',
    bgImageGradient: [ 'primary', '+1', ],
    title: [ 'neutral', '-1', ],
    titleLinksBlock: 'var(--articleLink-titleLinksBlock)',
    hoverBlockLink: 'inherit',
  },
  bg: {
    base: 'var(--main-bg-color)',
    '+1': [ 'neutral', '-5', ],
  },
  bodyText: {
    base: 'var(--main-text-color)',
    inverse: [ 'neutral', '-6', ],
  },
  boxy: {
    innerTextBg: [ 'primary', 'base', ],
    innerText: [ 'neutral', '-1', ],

    innerTextBgLabel: [ 'neutral', '-1', ],
    innerTextLabel: [ 'neutral', '-10', ],
  },
  breadCrumbs: {
    first: [ 'secondary', 'base', ],
    firstHover: [ 'secondary', '+1', ],
    rest: [ 'neutral', '-3', ],
    restHover: [ 'neutral', '-2', ],
    firstWebView: [ 'primary', 'base', ],
  },
  button: {
    // Primary
    primaryBg: 'white',
    primaryBorder: [ 'button', 'primaryText', ],
    primaryText: 'var(--button-primaryText)',
    primaryActiveBg: '#fff !important',
    primaryActiveBorder: [ 'button', 'primaryText', ],
    primaryActiveText: [ 'primary', '+1', ],
    primaryFocusBg: [ 'primary', '+1', ],
    primaryFocusBorder: [ 'button', 'primaryFocusBg', ],
    primaryFocusText: 'white',
    primaryHoverBg: [ 'primary', '-6', ],
    primaryHoverBorder: [ 'button', 'primaryHoverText', ],
    primaryHoverText: [ 'primary', 'base', ],

    // Primary Opaque
    primaryOpaqueBg: [ 'primary', 'base', ],
    primaryOpaqueBorder: 'transparent',
    primaryOpaqueText: 'white',
    primaryOpaqueActiveBg: [ 'primary', '+1', ],
    primaryOpaqueActiveBorder: 'transparent',
    primaryOpaqueActiveText: 'white',
    primaryOpaqueFocusBg: [ 'secondary', 'base', ],
    primaryOpaqueFocusBorder: 'transparent',
    primaryOpaqueFocusText: 'white',
    primaryOpaqueHoverBg: [ 'primary', '+1', ],
    primaryOpaqueHoverBorder: 'transparent',
    primaryOpaqueHoverText: 'white',

    // primaryDark
    primaryDarkBg: 'black',
    primaryDarkBorder: 'var(--button-primaryDarkText)',
    primaryDarkText: 'var(--button-primaryDarkText)',
    primaryDarkActiveBg: [ 'button', 'primaryText', ],
    primaryDarkActiveBorder: [ 'button', 'primaryActiveBg', ],
    primaryDarkActiveText: 'var(--button-primaryDarkActiveText)',
    primaryDarkFocusBg: [ 'button', 'primaryActiveBg', ],
    primaryDarkFocusBorder: [ 'button', 'primaryFocusBg', ],
    primaryDarkFocusText: [ 'button', 'primaryActiveText', ],
    primaryDarkHoverBg: 'var(--button-primaryDarkHoverBg)',
    primaryDarkHoverBorder: [ 'button', 'primaryHoverText', ],
    primaryDarkHoverText: [ 'primary', 'base', ],

    // Neutral
    neutralBg: 'transparent',
    neutralBorder: [ 'button', 'neutralText', ],
    neutralText: 'var(--button-neutralText)',
    neutralActiveBg: 'transparent !important',
    neutralActiveBorder: [ 'button', 'neutralText', ],
    neutralActiveText: [ 'button', 'neutralText', ],
    neutralFocusBg: [ 'neutral', '-2', ],
    neutralFocusBorder: [ 'button', 'neutralFocusBg', ],
    neutralFocusText: 'white',
    neutralHoverBg: 'var(--button-neutralHoverBg)',
    neutralHoverBorder: [ 'button', 'neutralHoverText', ],
    neutralHoverText: [ 'button', 'neutralText', ],
    // Neutral Opaque

    neutralOpaqueBg: 'var(--button-neutralOpaqueBg)',
    neutralOpaqueBorder: 'transparent',
    neutralOpaqueText: [ 'neutral', '-6', ],
    neutralOpaqueActiveBg: [ 'neutral', '-4', ],
    neutralOpaqueActiveBorder: 'transparent',
    neutralOpaqueActiveText: [ 'neutral', 'base', ],
    neutralOpaqueFocusBg: [ 'neutral', '-2', ],
    neutralOpaqueFocusBorder: [ 'neutral', '+2', ],
    neutralOpaqueFocusText: 'white',
    neutralOpaqueHoverBg: [ 'neutral', '-2', ],
    neutralOpaqueHoverBorder: 'transparent',
    neutralOpaqueHoverText: 'white',

    // Negative
    negativeBg: 'white',
    negativeBorder: 'var(--button-negativeBorder)',
    negativeText: [ 'negative', 'base', ],
    negativeActiveBg: '#fff !important',
    negativeActiveBorder: [ 'button', 'negativeText', ],
    negativeActiveText: [ 'negative', 'base', ],
    negativeFocusBg: [ 'negative', 'base', ],
    negativeFocusBorder: 'transparent',
    negativeFocusText: '#fff',
    negativeHoverBg: [ 'tertiary', '-4', ],
    negativeHoverBorder: [ 'button', 'negativeHoverText', ],
    negativeHoverText: [ 'negative', '+1', ],

    // Negative Opaque
    negativeOpaqueBg: [ 'negative', 'base', ],
    negativeOpaqueBorder: 'transparent',
    negativeOpaqueText: 'white',
    negativeOpaqueActiveBg: [ 'negative', 'base', ],
    negativeOpaqueActiveBorder: 'transparent',
    negativeOpaqueActiveText: 'white',
    negativeOpaqueFocusBg: [ 'negative', '+1', ],
    negativeOpaqueFocusBorder: [ 'neutral', 'base', ],
    negativeOpaqueFocusText: 'rgba(255,255,254,0.99)',
    negativeOpaqueHoverBg: [ 'negative', '+1', ],
    negativeOpaqueHoverBorder: 'transparent',
    negativeOpaqueHoverText: 'white',

    // Positive
    positiveBg: 'white',
    positiveBorder: [ 'button', 'positiveText', ],
    positiveText: [ 'positive', 'base', ],
    positiveActiveBg: '#fff !important',
    positiveActiveBorder: [ 'button', 'positiveText', ],
    positiveActiveText: [ 'positive', 'base', ],
    positiveFocusBg: [ 'positive', '-2', ],
    positiveFocusBorder: 'transparent',
    positiveFocusText: [ 'neutral', 'base', ],
    positiveHoverBg: [ 'positive', '-3', ],
    positiveHoverBorder: [ 'button', 'positiveHoverText', ],
    positiveHoverText: [ 'positive', '+1', ],

    // Positive Opaque
    positiveOpaqueBg: [ 'positive', 'base', ],
    positiveOpaqueBorder: 'transparent',
    positiveOpaqueText: 'white',
    positiveOpaqueActiveBg: [ 'positive', 'base', ],
    positiveOpaqueActiveBorder: 'transparent',
    positiveOpaqueActiveText: 'white',
    positiveOpaqueFocusBg: [ 'positive', '-1', ],
    positiveOpaqueFocusBorder: 'transparent',
    positiveOpaqueFocusText: [ 'neutral', 'base', ],
    positiveOpaqueHoverBg: [ 'positive', '-2', ],
    positiveOpaqueHoverBorder: 'transparent',
    positiveOpaqueHoverText: [ 'neutral', 'base', ],

    // Secondary
    secondaryBg: [ 'secondary', 'base', ],
    secondaryBorder: 'white',
    secondaryText: 'white',
    // todo: ask max about these colors
    secondaryActiveBg: [ 'secondary', '+1', ],
    secondaryActiveBorder: 'white',
    secondaryActiveText: 'white',
    secondaryFocusBg: [ 'secondary', '+2', ],
    secondaryFocusBorder: 'white',
    secondaryFocusText: 'white',
    secondaryHoverBg: [ 'secondary', '+1', ],
    secondaryHoverBorder: 'white',
    secondaryHoverText: 'white',

    // Secondary Opaque
    secondaryOpaqueBg: [ 'secondary', 'base', ],
    secondaryOpaqueBorder: 'transparent',
    secondaryOpaqueText: 'white',
    secondaryOpaqueActiveBg: [ 'secondary', '+1', ],
    secondaryOpaqueActiveBorder: 'transparent',
    secondaryOpaqueActiveText: 'white',
    secondaryOpaqueFocusBg: [ 'secondary', '+1', ],
    secondaryOpaqueFocusBorder: 'transparent',
    secondaryOpaqueFocusText: 'rgb(255,255,254)',
    secondaryOpaqueHoverBg: [ 'secondary', '+1', ],
    secondaryOpaqueHoverBorder: 'transparent',
    secondaryOpaqueHoverText: 'white',

    // Inverse
    inverseBg: 'transparent',
    inverseBorder: 'white',
    inverseText: 'white',
    inverseActiveBg: 'transparent',
    inverseActiveBorder: 'white',
    inverseActiveText: 'white',
    inverseFocusBg: 'rgba(255,255,255,0.1)',
    inverseFocusBorder: 'white',
    inverseFocusText: 'white',
    inverseHoverBg: 'rgba(255,255,255,0.2)',
    inverseHoverBorder: 'white',
    inverseHoverText: 'white',

    // Inverse Opaque
    inverseOpaqueBg: 'white',
    inverseOpaqueBorder: 'white',
    inverseOpaqueText: 'var(--button-inverseOpaqueText)',
    inverseOpaqueActiveBg: 'var(--main-bg-color)',
    inverseOpaqueActiveBorder: 'white',
    inverseOpaqueActiveText: 'var(--button-inverseOpaqueText)',
    inverseOpaqueFocusBg: 'var(--button-inverseOpaqueFocusBg)',
    inverseOpaqueFocusBorder: 'white',
    inverseOpaqueFocusText: 'var(--button-inverseOpaqueText)',
    inverseOpaqueHoverBg: 'rgba(255,255,255,0.8)',
    inverseOpaqueHoverBorder: 'white',
    inverseOpaqueHoverText: [ 'secondary', 'base', ],

    // dark Opaque
    darkOpaqueBg: [ 'neutral', '-1', ],
    darkOpaqueBorder: [ 'neutral', '-1', ],
    darkOpaqueText: [ 'positive', '-2', ],
    darkOpaqueActiveBg: [ 'neutral', '-2', ],
    darkOpaqueActiveBorder: [ 'neutral', '-2', ],
    darkOpaqueActiveText: [ 'positive', '-2', ],
    darkOpaqueFocusBg: [ 'neutral', '-2', ],
    darkOpaqueFocusBorder: [ 'neutral', '-2', ],
    darkOpaqueFocusText: [ 'positive', '-2', ],
    darkOpaqueHoverBg: [ 'neutral', '-2', ],
    darkOpaqueHoverBorder: [ 'neutral', '-2', ],
    darkOpaqueHoverText: [ 'positive', '-2', ],

    // Facebook
    facebookBg: 'white',
    facebookBorder: [ 'button', 'facebookText', ],
    facebookText: [ 'facebook', 'base', ],
    facebookActiveBg: '#fff !important',
    facebookActiveBorder: [ 'button', 'facebookText', ],
    facebookActiveText: [ 'facebook', 'base', ],
    facebookFocusBg: [ 'facebook', 'base', ],
    facebookFocusBorder: 'transparent',
    facebookFocusText: 'rgba(255, 255, 255, 1)',
    facebookHoverBg: [ 'facebook', '-2', ],
    facebookHoverBorder: [ 'button', 'facebookHoverText', ],
    facebookHoverText: [ 'facebook', '+1', ],

    // Opaque Facebook button
    facebookOpaqueBg: [ 'facebook', 'base', ],
    facebookOpaqueBorder: 'transparent',
    facebookOpaqueText: 'white',
    facebookOpaqueActiveBg: [ 'facebook', '-1', ],
    facebookOpaqueActiveBorder: 'transparent',
    facebookOpaqueActiveText: 'white',
    facebookOpaqueFocusBg: [ 'facebook', '+1', ],
    facebookOpaqueFocusBorder: 'transparent',
    facebookOpaqueFocusText: 'white',
    facebookOpaqueHoverBg: [ 'facebook', '+1', ],
    facebookOpaqueHoverBorder: 'transparent',
    facebookOpaqueHoverText: 'white',

    // Opaque formatting button
    formattingOpaqueBg: 'transparent',
    formattingOpaqueBorder: 'transparent',
    formattingOpaqueText: [ 'neutral', '-1', ],
    formattingOpaqueActiveBg: [ 'primary', 'base', ],
    formattingOpaqueActiveBorder: 'transparent',
    formattingOpaqueActiveText: 'white',
    formattingOpaqueFocusBg: [ 'primary', 'base', ],
    formattingOpaqueFocusBorder: 'transparent',
    formattingOpaqueFocusText: 'white',
    formattingOpaqueHoverBg: [ 'primary', '-5', ],
    formattingOpaqueHoverBorder: 'transparent',
    formattingOpaqueHoverText: [ 'neutral', '-1', ],

    // Twitter
    twitterBg: 'white',
    twitterBorder: [ 'button', 'twitterText', ],
    twitterText: [ 'twitter', 'base', ],
    twitterActiveBg: '#fff !important',
    twitterActiveBorder: [ 'button', 'twitterText', ],
    twitterActiveText: [ 'button', 'twitterText', ],
    twitterFocusBg: [ 'twitter', 'base', ],
    twitterFocusBorder: 'transparent',
    twitterFocusText: 'hsl(0, 0%, 100%)',
    twitterHoverBg: [ 'twitter', '-2', ],
    twitterHoverBorder: [ 'button', 'twitterHoverText', ],
    twitterHoverText: [ 'twitter', '+1', ],

    // Opaque Twitter button
    twitterOpaqueBg: [ 'twitter', 'base', ],
    twitterOpaqueBorder: 'transparent',
    twitterOpaqueText: 'white',
    twitterOpaqueActiveBg: [ 'twitter', '-1', ],
    twitterOpaqueActiveBorder: 'transparent',
    twitterOpaqueActiveText: 'white',
    twitterOpaqueFocusBg: [ 'twitter', '+1', ],
    twitterOpaqueFocusBorder: 'transparent',
    twitterOpaqueFocusText: 'white',
    twitterOpaqueHoverBg: [ 'twitter', '+1', ],
    twitterOpaqueHoverBorder: 'transparent',
    twitterOpaqueHoverText: 'white',

    // whatsapp
    whatsappBg: 'white',
    whatsappBorder: [ 'button', 'whatsappText', ],
    whatsappText: [ 'whatsapp', 'base', ],
    whatsappActiveBg: '#fff !important',
    whatsappActiveBorder: [ 'button', 'whatsappText', ],
    whatsappActiveText: [ 'button', 'whatsappText', ],
    whatsappFocusBg: [ 'whatsapp', 'base', ],
    whatsappFocusBorder: 'transparent',
    whatsappFocusText: 'hsla(0, 0%, 100%, 1)',
    whatsappHoverBg: [ 'whatsapp', '-2', ],
    whatsappHoverBorder: [ 'button', 'whatsappHoverText', ],
    whatsappHoverText: [ 'whatsapp', '+1', ],

    // Opaque whatsapp button
    whatsappOpaqueBg: [ 'whatsapp', 'base', ],
    whatsappOpaqueBorder: 'transparent',
    whatsappOpaqueText: [ 'bodyText', 'base', ],
    whatsappOpaqueActiveBg: [ 'whatsapp', '+2', ],
    whatsappOpaqueActiveBorder: 'transparent',
    whatsappOpaqueActiveText: [ 'bodyText', 'base', ],
    whatsappOpaqueFocusBg: [ 'whatsapp', '+1', ],
    whatsappOpaqueFocusBorder: 'transparent',
    whatsappOpaqueFocusText: [ 'bodyText', 'base', ],
    whatsappOpaqueHoverBg: [ 'whatsapp', '+1', ],
    whatsappOpaqueHoverBorder: 'transparent',
    whatsappOpaqueHoverText: [ 'bodyText', 'base', ],

    // Sales
    salesBg: 'white',
    salesBorder: [ 'button', 'salesText', ],
    salesText: [ 'sales', 'a11yOnLight', ],
    salesActiveBg: '#fff !important',
    salesActiveBorder: [ 'button', 'salesText', ],
    salesActiveText: [ 'button', 'salesText', ],
    salesFocusBg: [ 'sales', 'base', ],
    salesFocusBorder: 'transparent',
    salesFocusText: [ 'neutral', '-1', ],
    salesHoverBg: [ 'sales', '-2', ],
    salesHoverBorder: [ 'button', 'salesHoverText', ],
    salesHoverText: [ 'button', 'salesText', ],

    // Opaque Sales button
    salesOpaqueBg: [ 'sales', 'base', ],
    salesOpaqueBorder: 'transparent',
    salesOpaqueText: [ 'neutral', '-1', ],
    salesOpaqueActiveBg: [ 'sales', '+1', ],
    salesOpaqueActiveBorder: 'transparent',
    salesOpaqueActiveText: [ 'button', 'salesOpaqueText', ],
    salesOpaqueFocusBg: [ 'sales', '+2', ],
    salesOpaqueFocusBorder: 'transparent',
    salesOpaqueFocusText: [ 'button', 'salesOpaqueText', ],
    salesOpaqueHoverBg: [ 'sales', '+2', ],
    salesOpaqueHoverBorder: 'transparent',
    salesOpaqueHoverText: [ 'button', 'salesOpaqueText', ],

    sharebarText: [ 'neutral', '-3', ],
    sharebarBg: 'white',
    sharebarActiveBg: [ 'neutral', '-3', ],
    sharebarActiveBorder: 'transparent',
    sharebarActiveText: 'white',
    sharebarFocusBg: [ 'neutral', '-1', ],
    sharebarFocusBorder: 'transparent',
    sharebarFocusText: [ 'button', 'salesOpaqueText', ],
    sharebarHoverBg: [ 'neutral', '-3', ],
    sharebarHoverBorder: 'transparent',
    sharebarHoverText: 'white',
    // Commercial button
    commercialBg: '#ebebeb',
    commercialBorder: [ 'commercial', 'base', ],
    commercialText: [ 'commercial', 'base', ],
    commercialActiveBg: '#fff !important',
    commercialActiveBorder: [ 'commercial', 'base', ],
    commercialActiveText: [ 'commercial', 'base', ],
    commercialFocusBg: 'white',
    commercialFocusBorder: 'transparent',
    commercialFocusText: [ 'commercial', 'base', ],
    commercialHoverBg: [ 'neutral', '-7', ],
    commercialHoverBorder: [ 'commercial', 'base', ],
    commercialHoverText: [ 'commercial', 'base', ],

    // algolia search button
    algoliaText: [ 'neutral', '-1', ],

    paginationBg: 'var(--button-paginationBg)',
    paginationBorder: 'var(--button-paginationBorder)',
    paginationText: 'var(--button-paginationText)',
    paginationActiveBg: 'var(--button-paginationActiveBg)',
    paginationActiveBorder: 'var(--button-paginationActiveBorder)',
    paginationActiveText: 'var(--button-paginationActiveText)',
    paginationFocusBg: 'var(--button-paginationFocusBg)',
    paginationFocusBorder: 'var(--button-paginationFocusBorder)',
    paginationFocusText: 'var(--button-paginationFocusText)',
    paginationHoverBg: 'var(--button-paginationHoverBg)',
    paginationHoverBorder: 'var(--button-paginationHoverBorder)',
    paginationHoverText: 'var(--button-paginationHoverText)',

    // Default button theme
    defaultButtonThemeBg: 'transparent',
    defaultButtonThemeBorder: [ 'button', 'neutralText', ],
    defaultButtonThemeText: [ 'neutral', '-1', ],
    defaultButtonThemeActiveBg: 'transparent !important',
    defaultButtonThemeActiveBorder: [ 'button', 'neutralText', ],
    defaultButtonThemeActiveText: [ 'button', 'neutralText', ],
    defaultButtonThemeFocusBg: 'transparent',
    defaultButtonThemeFocusBorder: [ 'button', 'neutralText', ],
    defaultButtonThemeFocusText: [ 'neutral', '-1', ],
    defaultButtonThemeHoverBg: [ 'neutral', '-6', ],
    defaultButtonThemeHoverBorder: [ 'button', 'neutralHoverText', ],
    defaultButtonThemeHoverText: [ 'button', 'neutralText', ],
  },
  breakingNews: {
    layout: 'var(--layout-rowBg)',
    dayAndDate: 'var(--main-text-color)',
  },
  breakingNewsBox: {
    stripController: [ 'secondary', ],
  },
  biography: {
    link: [ 'neutral', '-10', ],
    linkHover: [ 'primary', '-1', ],
    linkFocus: [ 'primary', '-1', ],
    linkVisited: [ 'primary', '-1', ],
    linkActive: [ 'primary', '-1', ],
  },
  checkBox: {
    // Primary
    primaryBg: 'white',
    primaryBgChecked: [ 'primary', 'base', ],
    primaryBorder: [ 'primary', 'base', ],
    primaryBorderDisabled: [ 'neutral', '-5', ],
    primaryCheck: 'white',
    primaryRipple: [ 'primary', 'base', ],

    // Secondary
    secondaryBg: 'white',
    secondaryBgChecked: [ 'secondary', 'base', ],
    secondaryBorder: [ 'secondary', 'base', ],
    secondaryBorderDisabled: [ 'neutral', '-5', ],
    secondaryCheck: 'white',
    secondaryRipple: [ 'secondary', 'base', ],

    // Neutral
    neutralBg: 'white',
    neutralBgChecked: [ 'neutral', '-2', ],
    neutralBorder: [ 'neutral', '-2', ],
    neutralBorderDisabled: [ 'neutral', '-5', ],
    neutralCheck: 'white',
    neutralRipple: [ 'neutral', '-2', ],

    // TODO: Change This when TM design is approved
    // Tertiary
    tertiaryBg: 'white',
    tertiaryBgChecked: [ 'secondary', 'base', ],
    tertiaryBorder: [ 'secondary', 'base', ],
    tertiaryBorderDisabled: [ 'neutral', '-5', ],
    tertiaryCheck: 'white',
    tertiaryRipple: [ 'secondary', 'base', ],
  },

  credit: {
    creditArticleText: 'var(--credit-creditArticleText)',
  },

  footer: {
    bg: [ 'neutral', '-1', ],
    border: 'white',
    text: 'white',
  },

  comments: {
    authorName: 'var(--comments-authorName)',
    bg: 'var(--main-bg-color)',
    border: [ 'neutral', '-4', ],
    divider: [ 'primary', '+1', ],
    date: 'var(--comments-date)',
    highlightedCommentBg: 'var(--comments-highlightedCommentBg)',
    highlightStatus: [ 'primary', '-2', ],
    number: 'var(--comments-number)',
    report: 'var(--comments-report)',
    replyIcon: 'var(--comments-replay-icon)',
    cancelReplayBtn: 'var(--comments-cancelReplyBtn)',
    likeIcon: 'var(--comments-likeIcon)',
    likeCounter: 'var(--comments-likeCounter)',
    subcommentAuthor: 'var(--comments-subcommentAuthor)',
    subcommentBorder: [ 'primary', '-4', ],
    text: 'var(--main-text-color)',
    formElementsText: 'var(--comments-formElementsText)',
    formFieldBg: 'var(--comments-field-bg)',
    nameAlertTxt: 'var(--comments-nameAlertTxt)',
  },
  layout: {
    containerBg: 'var(--main-bg-color)',
    rowBg: 'var(--layout-rowBg)',
    sectionTitleA: 'var(--layout-sectionTitleA)',
  },
  liveBlog: {
    title: 'var(--live-blog-title)',
    bg: 'var(--main-bg-color)',
    text: 'var(--main-text-color)',
    border: 'var(--live-blog-border)',
    radioBg: 'var(--live-blog-radio-bg)',
    updatesButtonText: [ 'secondary', 'base', ],
    expandBtnFocus: 'var(--live-blog-expand-btn-focus)',
    avatar: 'var(--live-blog-avatar)',
    avatarBg: 'var(--live-blog-avatarBg)',
    liveUpdateBg: 'var(--live-blog-liveUpdateBg)',
    highlightedItemBg: 'var(--live-blog-highlightedItemBg)',
    iconShareFill: 'var(--live-blog-iconShareFillColor)',
  },
  magazine: {
    shareBarZen: [ 'neutral', '-2', ],
    articleHeader: 'inherit',
    headerText: [ 'primary', '+1', ],
  },
  authorBio: {
    infoBg: 'var(--authorBio-bg)',
    imageBg: [ 'neutral', '-6', ],
  },
  newsletter: {
    plusBg: [ 'neutral', '-2', ],
    plus: [ 'neutral', '-10', ],
    // Newsletter
    highlightBg: [ 'neutral', '-6', ],
    highlightTextTitle: [ 'neutral', '-1', ],
    highlightText: [ 'neutral', 'base', ],

    // Primary
    primaryBg: [ 'primary', '-2', ],
    primaryTextTitle: [ 'neutral', '-10', ],
    primaryText: [ 'neutral', '-10', ],

    // Single
    singleBg: [ 'neutral', '-10', ],
    singleTextTitle: [ 'neutral', '-1', ],
    singleText: [ 'neutral', '-10', ],
  },
  highlight: {
    base: [ 'quaternary', 'base', ],
    dimm: [ 'quaternary', '-2', ],
  },
  icon: {
    base: [ 'primary', 'base', ],
    text: [ 'neutral', '-3', ],
  },
  a11yMenu: {
    text: [ 'primary', 'base', ],
    textOpenOrHover: [ 'neutral', '-10', ],
    bgOpen: [ 'secondary', 'base', ],
    bgHover: [ 'primary', 'base', ],
  },
  userMenu: {
    bgHover: [ 'neutral', '-3', ],
    bgOpen: [ 'neutral', '-2', ],
    iconColor: [ 'secondary', 'base', ],
    text: [ 'neutral', '-3', ],
    textOpenOrHover: [ 'neutral', '-10', ],
  },
  headerSearch: {
    text: [ 'neutral', '-3', ],
    bgHover: [ 'primary', 'base', ],
    bgInputOpen: [ 'primary', '-4', ],
    textOpenOrHover: [ 'neutral', '-10', ],
    bgOpen: [ 'secondary', 'base', ],
  },
  masthead: {
    bgButtonIconColor: [ 'neutral', '-3', ],
    border: [ 'neutral', '-2', ],
    icon: [ 'secondary', 'base', ],
    textSiteLinks: [ 'primary', '-3', ],
    textSiteLinksHover: [ 'primary', '-4', ],
    bgButtonHover: [ 'neutral', '-3', ],
    bgMenu: [ 'neutral', '-2', ],
    bgMenuSites: [ 'neutral', '-1', ],
    bgMenuHover: [ 'neutral', '-3', ],
    menuItemBorder: [ 'neutral', '-3', ],
    subMenuBg: [ 'neutral', '-1', ],
    subMenuBgHover: [ 'neutral', '-2', ],
    flippingArrow: [ 'neutral', '-10', ],
    flippingArrowButtonBgOpen: [ 'neutral', '-3', ],
  },
  labelMasthead: {
    bgMenuSites: [ 'neutral', '-6', ],
    textSiteLinks: [ 'commercial', 'base', ],
  },
  mobileNav: {
    barText: [ 'neutral', '-1', ],
    barBorder: [ 'neutral', '-1', ],
    skewedBg: [ 'neutral', '-2', ],
    promotionBg: [ 'quaternary', '-2', ],
    avatarBg: [ 'primary', '-1', ],
    sectionLink: [ 'neutral', '-1', ],
    siteLink: [ 'secondary', 'base', ],
    moreBackBg: [ 'primary', '-1', ],
    moreBackArrow: [ 'neutral', '-1', ],
    barIcons: [ 'neutral', '-3', ],
    logoBg: [ 'primary', 'base', ],
    logoBgActive: [ 'secondary', 'base', ],
    divider: [ 'primary', 'base', ],
    headerIcon: [ 'quaternary', '-3', ],
    gradientStart: '#f4d835',
    gradientEnd: '#fff169',

    labelText: [ 'commercial', 'base', ],
    labelBg: [ 'neutral', '-7', ],
    labelBorder: [ 'neutral', '-4', ],
  },

  marketingNotification: {
    closeIcon: [ 'neutral', '-1', ],
  },

  paragraph: {
    markBg: 'var(--paragraph-markBg)',
  },

  paywall: {
    text: [ 'neutral', '-1', ],
    inverseText: [ 'neutral', '-1', ],
    primaryGradientStart: '#97ebe9',
    primaryGradientEnd: '#f2fadf',
    secondaryGradientStart: '#cccccc',
    secondaryGradientEnd: '#f5f5f5',
    textMidPage: [ 'neutral', '-1', ],
  },
  personalArea: {
    calendarBase: [ 'neutral', '-2', ],
    betweenSelectedDates: [ 'neutral', '-5', ],
  },
  downloadAplicationPopUp: {
    gradientStart: '#3bb08b',
    gradientEnd: '#0f480f',
  },
  select: {
    // Primary
    primaryBg: 'white',
    primaryBorder: [ 'secondary', 'base', ],
    primaryBorderItem: 'var(--select-primaryBorderItem)',
    primaryArrowColor: [ 'secondary', 'base', ],
    primaryTextColor: [ 'secondary', 'base', ],

    // Primary highlighted
    primaryHighlightedBg: [ 'primary', '-6', ],
    // Primary hover
    primaryHoverBg: [ 'primary', '-6', ],
    // Primary Focus
    primaryFocusBg: [ 'primary', '-6', ],

    // neutral
    neutralBg: 'white',
    neutralBorder: [ 'bodyText', 'base', ],
    neutralBorderItem: [ 'bodyText', 'base', ],
    neutralArrowColor: [ 'bodyText', 'base', ],
    neutralTextColor: 'var(--select-neutralTextColor)',

    // neutral highlighted
    neutralHighlightedBg: [ 'neutral', '-6', ],
    // neutral hover
    neutralHoverBg: [ 'neutral', '-6', ],
    // neutral Focus
    neutralFocusBg: [ 'neutral', '-6', ],

    // neutralDark
    neutralContactUsBg: 'var(--select-neutralContactUsBg)',
    neutralContactUsBorder: 'var(--select-neutralContactUsBorder)',
    neutralContactUsBorderItem: 'var(--select-neutralContactUsBorderItem)',
    neutralContactUsArrowColor: 'var(--select-neutralContactUsArrowColor)',
    neutralContactUsTextColor: 'var(--select-neutralContactUsTextColor)',

    // neutral highlighted
    neutralContactUsHighlightedBg: 'var(--select-neutralContactUsHighlightedBg)',
    // neutral hover
    neutralContactUsHoverBg: 'var(--select-neutralContactUsHoverBg)',
    // neutral Focus
    neutralContactUsFocusBg: 'var(--select-neutralContactUsFocusBg)',

    // Graph
    graphBg: [ 'neutral', '-5', ],
    graphBorder: [ 'neutral', '-5', ],
    graphBorderItem: [ 'neutral', '-5', ],
    graphArrowColor: [ 'neutral', 'base', ],
    graphTextColor: [ 'neutral', 'base', ],

    // Primary highlighted
    graphHighlightedBg: [ 'neutral', '-6', ],
    // Primary hover
    graphHoverBg: [ 'neutral', '-6', ],
    // Primary Focus
    graphFocusBg: [ 'neutral', '-6', ],
  },

  tldr: {
    bg: 'var(--tldr-bg)',
    buttonBg: 'var(--tldr-buttonBg)',
    buttonBgHover: 'var(--tldr-buttonBgHover)',
    buttonBgActive: 'var(--tldr-buttonBgActive)',
    buttonIcon: 'var(--tldr-buttonIcon)',
  },

  input: {
    // Primary
    primaryBg: 'var(--input-primaryBg)',
    primaryBgWrapper: 'transparent',
    primaryBorder: [ 'neutral', '-5', ],
    primaryBorderTextLabel: [ 'neutral', '-5', ],
    primaryPlaceholder: [ 'neutral', '-4', ],
    primaryText: [ 'bodyText', 'base', ],
    primaryTextLabel: 'var(--input-primaryTextLabel)',
    primaryTextLabelDisabled: 'var(--input-primaryTextLabelDisabled)',
    primaryTextNote: [ 'neutral', '-3', ],
    primaryAbbr: [ 'tertiary', 'base', ],

    // Primary Focus
    primaryFocusBg: 'white',
    primaryFocusBorder: [ 'input', 'primaryTextLabel', ],

    // Primary Error state
    primaryErrorBorder: [ 'tertiary', '+1', ],
    primaryErrorText: [ 'bodyText', 'base', ],
    primaryErrorTextLabel: [ 'tertiary', 'base', ],
    primaryErrorTextNote: [ 'tertiary', 'base', ],

    // Primary Hover
    primaryHoverBg: [ 'primary', '-5', ],
    primaryHoverBorder: [ 'input', 'primaryTextLabel', ],
    primaryHoverText: [ 'bodyText', 'base', ],

    // PrimaryContactUs
    primaryContactUsBg: 'var(--input-primaryContactUsBg)',
    primaryContactUsBgWrapper: 'transparent',
    primaryContactUsBorder: 'var(--input-primaryContactUsBorder)',
    primaryContactUsBorderTextLabel: 'var(--input-primaryContactUsBorderTextLabel)',
    primaryContactUsPlaceholder: [ 'neutral', '-4', ],
    primaryContactUsText: [ 'bodyText', 'base', ],
    primaryContactUsTextLabel: 'var(--input-primaryContactUsTextLabel)',
    primaryContactUsTextLabelDisabled: 'var(--input-primaryContactUsTextLabelDisabled)',
    primaryContactUsTextNote: 'var(--input-primaryContactUsTextNote)',
    primaryContactUsAbbr: [ 'tertiary', 'base', ],

    // Primary ContactUs Focus
    primaryContactUsFocusBg: 'var(--input-primaryContactUsFocusBg)',
    primaryContactUsFocusBorder: 'var(--input-primaryContactUsFocusBorder)',

    // Primary ContactUs Error state
    primaryContactUsErrorBorder: [ 'tertiary', '+1', ],
    primaryContactUsErrorText: [ 'bodyText', 'base', ],
    primaryContactUsErrorTextLabel: [ 'tertiary', 'base', ],
    primaryContactUsErrorTextNote: [ 'tertiary', 'base', ],

    // Primary ContactUs Hover
    primaryContactUsHoverBg: [ 'primary', '-5', ],
    primaryContactUsHoverBorder: 'var(--input-primaryContactUsHoverBorder)',
    primaryContactUsHoverText: [ 'bodyText', 'base', ],

    // Neutral
    neutralBg: 'var(--input-neutralBg)',
    neutralBgWrapper: 'transparent',
    neutralBorder: 'var(--input-neutralBorder)',
    neutralBorderTextLabel: [ 'neutral', '-5', ],
    neutralPlaceholder: [ 'neutral', '-4', ],
    neutralText: [ 'bodyText', 'base', ],
    neutralTextLabel: 'var(--input-neutralTextLabel)',
    neutralTextLabelDisabled: 'var(--input-neutralTextLabelDisabled)',
    neutralTextNote: [ 'neutral', '-3', ],
    neutralAbbr: [ 'tertiary', 'base', ],

    // neutral Focus
    // neutralFocusBg: 'white',
    neutralFocusBg: 'var(--input-neutralBgFocus)',
    neutralFocusBorder: [ 'input', 'neutralTextLabel', ],

    // neutral Error state
    neutralErrorBorder: [ 'tertiary', '+1', ],
    neutralErrorText: [ 'bodyText', 'base', ],
    neutralErrorTextLabel: [ 'tertiary', 'base', ],
    neutralErrorTextNote: [ 'tertiary', 'base', ],

    // neutral Hover
    neutralHoverBg: 'var(--input-neutralBgHover)',
    neutralHoverBorder: [ 'input', 'neutralTextLabel', ],
    neutralHoverText: [ 'bodyText', 'base', ],

    // Primary Opaque
    primaryOpaqueBg: 'white',
    primaryOpaqueBgWrapper: 'transparent',
    primaryOpaqueBorder: [ 'primary', '-4', ],
    primaryOpaqueBorderTextLabel: [ 'primary', '-5', ],
    primaryOpaquePlaceholder: [ 'neutral', '-4', ],
    primaryOpaqueText: [ 'neutral', '-1', ],
    primaryOpaqueTextLabel: [ 'primary', '+1', ],
    primaryOpaqueTextLabelDisabled: [ 'neutral', '-4', ],
    primaryOpaqueTextNote: [ 'neutral', '-3', ],
    primaryOpaqueAbbr: [ 'tertiary', 'base', ],

    // Primary Opaque Focus
    primaryOpaqueFocusBg: 'white',
    primaryOpaqueFocusBorder: [ 'primary', 'base', ],

    // Primary Opaque Error state
    primaryOpaqueErrorBorder: [ 'tertiary', '+1', ],
    primarOpaqueErrorText: [ 'bodyText', 'base', ],
    primaryOpaqueErrorTextLabel: [ 'tertiary', 'base', ],
    primaryOpaqueErrorTextNote: [ 'tertiary', 'base', ],

    // Primary Opaque Hover
    primaryOpaqueHoverBg: [ 'primary', '-5', ],
    primaryOpaqueHoverBorder: [ 'primary', '-4', ],
    primaryOpaqueHoverText: [ 'bodyText', 'base', ],

    // PrimaryInverse
    primaryInverseBg: 'white',
    primaryInverseBgWrapper: 'transparent',
    primaryInverseBorder: [ 'primary', '-4', ],
    primaryInverseBorderTextLabel: [ 'primary', '-5', ],
    primaryInversePlaceholder: [ 'neutral', '-4', ],
    primaryInverseText: [ 'bodyText', 'base', ],
    primaryInverseTextLabel: [ 'primary', '+1', ],
    primaryInverseTextLabelDisabled: [ 'neutral', '-4', ],
    primaryInverseTextNote: 'white',
    primaryInverseAbbr: [ 'primary', 'base', ],

    // PrimaryInverse Focus
    primaryInverseFocusBg: 'white',
    primaryInverseFocusBorder: [ 'primary', 'base', ],

    // PrimaryInverse Error state
    primaryInverseErrorBorder: [ 'tertiary', '+1', ],
    primaryInverseErrorText: [ 'bodyText', 'base', ],
    primaryInverseErrorTextLabel: [ 'tertiary', 'base', ],
    primaryInverseErrorTextNote: [ 'tertiary', 'base', ],

    // PrimaryInverse Hover
    primaryInverseHoverBg: [ 'primary', '-5', ],
    primaryInverseHoverBorder: [ 'primary', '-4', ],
    primaryInverseHoverText: [ 'bodyText', 'base', ],

    // PrimaryMediumInverse
    primaryMediumInverseBg: 'white',
    primaryMediumInverseBgWrapper: 'transparent',
    primaryMediumInverseBorder: [ 'primary', '-4', ],
    primaryMediumInverseBorderTextLabel: [ 'primary', '-5', ],
    primaryMediumInversePlaceholder: [ 'neutral', '-4', ],
    primaryMediumInverseText: [ 'bodyText', 'base', ],
    primaryMediumInverseTextLabel: [ 'primary', '+1', ],
    primaryMediumInverseTextLabelDisabled: [ 'neutral', '-4', ],
    primaryMediumInverseTextNote: [ 'secondary', 'base', ],
    primaryMediumInverseAbbr: [ 'primary', 'base', ],

    // PrimaryMediumInverse Focus
    primaryMediumInverseFocusBg: 'white',
    primaryMediumInverseFocusBorder: [ 'primary', 'base', ],

    // PrimaryInverse Error state
    primaryMediumInverseErrorBorder: [ 'tertiary', '+1', ],
    primaryMediumInverseErrorText: [ 'bodyText', 'base', ],
    primaryMediumInverseErrorTextLabel: [ 'tertiary', 'base', ],
    primaryMediumInverseErrorTextNote: [ 'tertiary', 'base', ],

    // PrimaryInverse Hover
    primaryMediumInverseHoverBg: [ 'primary', '-5', ],
    primaryMediumInverseHoverBorder: [ 'primary', '-4', ],
    primaryMediumInverseHoverText: [ 'bodyText', 'base', ],

    // Search
    searchBg: 'transparent',
    searchBgWrapper: 'transparent',
    searchBorder: 'transparent',
    searchBorderTextLabel: [ 'primary', '-5', ],
    searchPlaceholder: [ 'neutral', '-4', ],
    searchText: [ 'bodyText', 'base', ],
    searchTextLabel: [ 'primary', '+1', ],
    searchTextLabelDisabled: [ 'neutral', '-4', ],
    searchTextNote: [ 'neutral', '-3', ],
    searchAbbr: [ 'tertiary', 'base', ],

    // Search Focus
    searchFocusBg: 'transparent',
    searchFocusBorder: 'transparent',

    // Search Error state
    searchErrorBorder: [ 'tertiary', '+1', ],
    searchErrorText: [ 'bodyText', 'base', ],
    searchErrorTextLabel: [ 'tertiary', 'base', ],
    searchErrorTextNote: [ 'tertiary', 'base', ],

    // Search Hover
    searchHoverBg: 'transparent',
    searchHoverBorder: 'transparent',
    searchHoverText: [ 'bodyText', 'base', ],

    // Newsletter
    newsletterBg: 'white',
    newsletterBorder: [ 'neutral', '-5', ],
    newsletterBorderTextLabel: [ 'neutral', '-6', ], // maybe - not sure what this is
    newsletterText: [ 'bodyText', 'base', ],
    newsletterTextNote: [ 'neutral', '-2', ],
    newsletterTextLabel: [ 'secondary', 'base', ],
    newsletterAbbr: [ 'secondary', 'base', ],
    newsletterFocusBg: 'white',
    newsletterFocusBorder: [ 'secondary', 'base', ],
    newsletterHoverBg: 'white',
    newsletterHoverBorder: [ 'neutral', '-5', ],
    newsletterHoverText: [ 'bodyText', 'base', ],
    newsletterErrorBorder: [ 'tertiary', '+1', ],
    newsletterErrorTextLabel: [ 'tertiary', '+1', ],
    newsletterErrorTextNote: [ 'tertiary', '+1', ],
  },
  image: {
    bgc: 'var(--image-bg)',
  },
  link: {
    base: 'var(--link-base)',
    textLink: 'var(--link-textLink)',
    mainBlockBase: [ 'secondary', 'base', ],
    active: [ 'secondary', 'base', ],
    borderBottom: 'var(--link-borderBottom)',
    borderBottomHover: 'transparent',
    a11yOnDark: [ 'primary', '-2', ],
    a11yOnLight: [ 'primary', '+1', ],
    darkMainBlockRelatedArticles: [ 'primary', 'base', ],
  },
  list: {
    listViewHeader: 'var(--list-listViewHeader)',
    listViewHeaderIconBack: 'white',
    listViewHeaderIconBackBg: [ 'primary', 'base', ],
    listViewHeaderCommercial: [ 'commercial', 'base', ],
  },
  radioButton: {
    // Primary
    primaryBg: 'white',
    primaryBgChecked: [ 'secondary', 'base', ],
    primaryBorder: [ 'secondary', 'base', ],
    primaryBorderDisabled: [ 'neutral', '-5', ],
    primaryCheck: 'white',
    primaryRipple: [ 'secondary', 'base', ],

    // Secondary
    secondaryBg: 'white',
    secondaryBgChecked: [ 'secondary', 'base', ],
    secondaryBorder: [ 'secondary', 'base', ],
    secondaryBorderDisabled: [ 'neutral', '-5', ],
    secondaryCheck: 'white',
    secondaryRipple: [ 'secondary', 'base', ],

    // TODO: Change This when TM design is approved
    // Tertiary
    tertiaryBg: 'white',
    tertiaryBgChecked: 'var(--radioButton-tertiaryBgChecked)',
    tertiaryBorder: 'var(--radioButton-tertiaryBorder)',
    tertiaryBorderDisabled: [ 'neutral', '-5', ],
    tertiaryCheck: 'white',
    tertiaryRipple: [ 'secondary', 'base', ],
  },

  sales: {
    '-2': '#FFF7E5',
    '-1': '#FFBD45',
    base: '#FFA500',
    '+1': '#FA9600',
    '+2': '#ED8600',
    a11yOnLight: '#A7610C',
  },
  shareBar: {
    button: [ 'neutral', '-2', ],
    buttonHover: [ 'neutral', '-1', ],
    borderTop: [ 'neutral', '-5', ],
  },

  // state
  disabled: {
    text: [ 'neutral', '-4', ],
    bg: [ 'neutral', '-5', ],
  },

  negative: {
    '-2': '#ff8585',
    '-1': [ 'tertiary', '-1', ],
    base: [ 'tertiary', 'base', ],
    '+1': [ 'tertiary', '+1', ],
    a11yOnDark: [ 'tertiary', '-1', ],
  },
  positive: {
    '-3': '#E7FFE5',
    '-2': '#6BCC66',
    '-1': '#5BB856',
    base: 'var(--positive-base)',
    '+1': '#266D22',
    '+2': '#194716',
    a11yOnDark: [ 'positive', '-1', ],
  },

  // social
  facebook: {
    '-2': '#F2F6FF',
    '-1': '#4766A6',
    base: '#3b5998',
    '+1': '#2A4682',
    messenger: '#0084FF',
  },
  twitter: {
    '-2': '#F2FAFF',
    '-1': '#67BEF4',
    base: '#1DA1F2',
    '+1': '#1888CC',
    '+2': '#1577B2',
  },
  whatsapp: {
    '-2': '#F0FFF5',
    '-1': '#58F593',
    base: '#25D366',
    '+1': '#12B850',
    '+2': '#08993E',
  },
  specialPromotions: {
    primaryBg: [ 'primary', 'base', ],
    primaryInverseBg: [ 'tertiary', 'base', ],
  },
  quote: {
    text: 'var(--quote-text)',
    visualElement: 'var(--quote-visualElement)',
    creditHighlight: 'transparent',
    // creditHighlight: 'var(--quote-creditHighlight)',
    creditText: 'var(--quote-creditText)',
  },
  quoteTeaser: {
    bg: [ 'primary', '-2', ],
    bgMobile: [ 'neutral', '-2', ],
    svg: 'white',
    svgMobile: [ 'primary', '-2', ],
    text: [ 'bodyText', 'base', ],
    textMobile: 'white',
    footer: [ 'bodyText', 'base', ],
    footerMobile: 'white',
  },
  teaserFooter: {
    comments: [ 'neutral', '-3', ],
  },
  vogelList: {
    title: [ 'neutral', '-1', ],
    titleBg: [ 'layout', 'rowBg', ],
  },
  topNews: {
    item: [ 'neutral', '-1', ],
    lastItem: [ 'secondary', 'base', ],
    hoverUnderline: [ 'secondary', 'base', ],
  },
  calculonList: {
    t5FooterColor: [ 'primary', '-2', ],
    t5Kicker: [ 'primary', '-3', ],
  },
  zoidbergList: {
    title: [ 'neutral', '-1', ],
  },
  garciaList: {
    authorColor: [ 'secondary', 'base', ],
  },
  mousePad: {
    index: [ 'neutral', '-3', ],
  },

  tabElement: {
    tabText: [ 'neutral', '-2', ],
    activeTabBg: 'white',
    activeTabColor: [ 'neutral', '-1', ],
    focusBorderBottom: 'transparent',
    activeBorderTop: [ 'neutral', '-1', ],
    tabsBg: 'transparent',
    tabListBg: 'transparent',
    tabListText: [ 'neutral', '-2', ],
    tabListBorderBottom: 'transparent',
  },

  osaka: {
    text: [ 'neutral', '-1', ],
    footerText: [ 'neutral', '-3', ],
    nextBtnBg: [ 'primary', 'base', ],
    border: [ 'neutral', '-4', ],
    borderEnd: [ 'neutral', '-4', ],
    promotedBg: [ 'neutral', '-6', ],


  },

  schoonhoven: {
    kicker: [ 'secondary', 'base', ],
    rssLink: [ 'secondary', '+1', ],
    rssLinkMobile: [ 'secondary', 'base', ],
  },

  audioPlayerArticle: {
    bg: 'white',
    bgWebView: 'var(--audioPlayerArticle-bgWebView)',
    color: [ 'neutral', '-1', ],
    playBg: [ 'neutral', '-1', ],
    playBgWebView: 'var(--audioPlayerArticle-playBgWebView)',
    playBgPodcastWebView: 'var(--audioPlayerArticle-playBgPodcastWebView)',
    rail: '#d7d7d7',
    progressBar: [ 'quaternary', 'base', ],
    mozFocusRing: [ 'quaternary', 'base', ],
    playBtn: 'var(--audioPlayerArticle-playBg)',
    playBtnPodcastWebView: [ 'neutral', '-1', ],
    borderColor: '#d7d7d7',
    exclusiveColor: [ 'tertiary', 'base', ],
    titleColor: [ 'neutral', '-1', ],
  },

  audioPlayerArticleBody: {
    header: [ 'secondary', '+1', ],
  },

  audioPlayer: {
    bg: [ 'neutral', '-1', ],
    color: 'white',
    playBg: 'white',
    rail: 'white',
    progressBar: [ 'primary', 'base', ],
    mozFocusRing: [ 'primary', 'base', ],
    playBtn: [ 'primary', 'base', ],

    listItemBg: 'transparent',
    listItemColor: [ 'neutral', '-2', ],
    listItemPlayBg: [ 'neutral', '-6', ],
    listItemPlayBtn: [ 'primary', 'base', ],
    listItemRail: [ 'neutral', '-6', ],
  },

  audioPlayerLabel: {
    bg: [ 'primary', 'base', ],
    color: 'white',
    playBg: [ 'neutral', '-2', ],
    rail: [ 'neutral', '-7', ],
    progressBar: [ 'neutral', '-7', ],
    mozFocusRing: [ 'neutral', '-7', ],
    playBtn: [ 'primary', 'base', ],

    listItemBg: 'transparent',
    listItemColor: [ 'neutral', '-2', ],
    listItemPlayBg: [ 'neutral', '-7', ],
    listItemPlayBtn: [ 'neutral', '-2', ],
    listItemRail: [ 'neutral', '-2', ],
  },

  toolbar: {
    buttonHoverBg: [ 'neutral', '+1', ],
  },
  gallery: {
    highlight: [ 'quaternary', 'base', ],
  },

  noteLink: {
    color: [ 'primary', 'base', ],
  },

  podcastArticle: {
    channelLink: 'var(--main-text-color)',
    channelLinkHover: [ 'neutral', '-3', ],
    headerTextColor: [ 'neutral', '-1', ],
    headerTextColorWebView: 'white',
    headlineBorderColor: [ 'layout', 'rowBg', ],
    headerAudioText: 'white',
    headerAudioPlay: [ 'neutral', '-1', ],
    headerAudioPlayBg: [ 'primary', 'base', ],
    headerAudioProgress: [ 'podcastArticle', 'headerAudioPlayBg', ],
    headerAudioProgressRail: [ 'neutral', '-6', ],
  },


  recipeArticle: {
    headlineTextColor: [ 'neutral', '-1', ],
    headerTextColor: [ 'neutral', '-1', ],
    headlineBorderColor: [ 'primary', ],
    iconColor: [ 'primary', ],
  },

  gplus: '#DB4437',
  linkedin: '#0077B5',
  pinterest: '#BD081C',
  snapchat: '#FFFC00',

  searchEngine: {
    searchBg: 'white',
    searchFg: [ 'secondary', 'base', ],
    searchLogo: [ 'primary', ],
    autoCompleteBorder: 'currentColor',
    resultsBg: [ 'neutral', '-6', ],
    resultsFg: [ 'neutral', '-1', ],
    instantSearchWrapperBg: [ 'neutral', '-6', ],
    resultsLogo: [ 'primary', ],
    suggestionsBg: 'white',
    suggestionsHighlightedBg: [ 'primary', '-5', ],
    secondarySuggestionsBg: 'white',
    logo: 'IconMarkerLogo',
    hotTopicsBg: [ 'neutral', '-6', ],
    hotTopicsText: [ 'secondary', 'base', ],
    hotTopicsHeadline: [ 'neutral', '-1', ],
  },

  mainGradient: {
    base: 'var(--main-gradient)',
  },

  gradientA: {
    from: [ 'neutral', '-3', ],
    to: [ 'neutral', '+1', ],
  },

  gradientB: {
    from: [ 'secondary', '+2', ],
    to: [ 'secondary', '-4', ],
    fgColor: [ 'neutral', '-10', ],
    logoColor: [ 'neutral', '-10', ],
  },

  topStrip: {
    text: 'white',
    a11yText: 'white',
  },
  tags: {
    header: [ 'secondary', 'base', ],
    tag: 'var(--tags-tag)',
    icon: [ 'neutral', '-2', ],
    iconSuccess: [ 'positive', 'base', ],
  },


  blackTheme: {
    backgroundColor: [ 'neutral', 'base', ],
    textColor: [ 'neutral', '-10', ],
    shareBarButton: [ 'neutral', '-10', ],
    shareBarButtonHover: [ 'quaternary', 'base', ],
    captionText: 'var(--caption-color)',
    mastheadBg: [ 'neutral', 'base', ],
    mastheadText: [ 'neutral', '-10', ],
    mastheadLogo: [ 'neutral', '-10', ],
    mastheadIcon: [ 'neutral', '-10', ],
    mastheadBorder: 'transparent',
  },

  yellowTheme: {
    backgroundColor: [ 'neutral', '-10', ],
    textColor: [ 'neutral', '-1', ],
    shareBarButton: [ 'neutral', '-2', ],
    shareBarButtonHover: [ 'neutral', '-1', ],
    captionText: 'var(--caption-color)',
    mastheadBg: [ 'neutral', '-10', ],
    mastheadText: [ 'neutral', '-3', ],
    mastheadLogo: [ 'positive', 'base', ],
    mastheadIcon: [ 'positive', 'base', ],
    mastheadBorder: [ 'positive', 'base', ],
  },
  topicInfoHeader: {
    textColor: [ 'neutral', '-1', ],
    borderColor: [ 'neutral', '-1', ],
    logoFill: [ 'primary', 'base', ],
    logoColor: [ 'neutral', '-5', ],
    avatarBg: '#f6f6f6',
  },
  logoLight: {
    tm: [ 'neutral', '-5', ],
    hdc: 'var(--logoLight-hdc)',
    htz: 'var(--logoLight-htz)',
  },

  defaultTheme: {
    backgroundColor: [ 'neutral', '-10', ],
    textColor: [ 'neutral', '-1', ],
    shareBarButton: [ 'neutral', '-2', ],
    shareBarButtonHover: [ 'neutral', '-1', ],
    captionText: [ 'neutral', '-1', ],
    mastheadBg: [ 'neutral', '-10', ],
    mastheadText: [ 'neutral', '-3', ],
    mastheadLogo: [ 'positive', 'base', ],
    mastheadIcon: [ 'positive', 'base', ],
    mastheadBorder: [ 'positive', 'base', ],
  },

  commentsPaywall: {
    bgc: '#efe2d3',
  },

  labels: {
    relatedArticleTitle: '#c02c8e',
  },

  Infographics: {
    main: 'var(--infographic-main)',
    bg: 'var(--infographic-bg)',
    innerBg: 'var(--infographic-innerBg)',
    regularColumn: 'var(--infographic-regularColumn)',
    boldColumn: 'var(--infographic-boldColumn)',
    timelineBorder: 'var(--infographic-timelineBorder)',
    timelineBullet: 'var(--infographic-timelineBullet)',
    timelineTitle: 'var(--infographic-timelineTitle)',
    bulletContent: 'var(--infographic-bulletContent)',
    text: 'var(--infographic-text)',
    chatBg: 'var(--infographic-chatBg)',
    qaBorder: 'var(--infographic-qaBorder)',
  },

  Weekend: {
    bg: [ 'primary', '-4', ],
    innerBg: [ 'neutral', '-7', ],
    exclusive: [ 'secondary', 'base', ],
  },

  readingList: {
    primary: [ 'neutral', '-2', ],
    highlight: [ 'primary', 'base', ],
  },

  seriesArticles: {
    text: 'var(--seriesArticles-text)',
    border: 'var(--seriesArticles-borderColor)',
    loadMore: 'var(--seriesArticles-loadMore)',
    focusBg: 'var(--seriesArticles-focusBg)',
  },

  reviewAmenities: {
    bg: 'var(--reviewAmenities-bg)',
    innerBg: 'var(--reviewAmenities-innerBg)',
  },

  gameArticle: {
    bg: [ 'primary', '-6', ],
    innerBg: [ 'neutral', '-10', ],
    statisticsText: [ 'neutral', '-2', ],
    tabNotActive: [ 'button', 'primaryOpaqueHoverBg', ],
    border: [ 'primary', '-6', ],
    headerTabBg: [ 'primary', '-6', ],
    headerTabText: [ 'neutral', '-3', ],
  },

  placeholder: {
    bg: 'var(--placeholder-bg)',
  },

  marco: {
    bg: [ 'neutral', '-6', ],
  },

  conferenceArticle: {
    bgColor: [ 'neutral', '-6', ],
    color: [ 'neutral', '-1', ],
    HColor: [ 'neutral', '-1', ],
    bgShareIcons: [ 'neutral', '-1', ],
    colorShareIcons: [ 'neutral', '-6', ],
    teaserColor: [ 'neutral', '-1', ],
    borderColor: '#2CA535',
    pColor: [ 'neutral', '-1', ],
  },

  giftTooltip: {
    bg: 'var(--color-neutral--6)',
    border: 'var(--color-neutral-base)',
    icon: 'var(--color-neutral--2)',
  },
};

export default colors;
